import TwitterIcon from '../../../../assets/icons-social/twitter.svg';
import GithubIcon from '../../../../assets/icons-social/github.svg';
import YoutubeIcon from '../../../../assets/icons-social/youtube.svg';
import { youtubeLink } from '../../../../appconfig';
import { twitterLink } from '../../../../appconfig';
import { githubLink } from '../../../../appconfig';

export const CopyrightNotice = () => {
  return (
    <div className="bg-cosmith-primary-900 border-t border-white/20 px-4 py-4 flex flex-col gap-6 lg:flex-row lg:justify-between lg:px-8 xl:px-[120px]">
      <p className="text-white/60">
        {`Copyright 2022-${new Date().getFullYear()} cosmith.io. All rights reserved.`}
      </p>
      <ul className="flex gap-4">
        <li className="hover:scale-150 transition-transform duration-300 ease-out">
          <a href={youtubeLink} target="_blank" rel='noreferrer'>
            <img src={YoutubeIcon} alt="facebook" />
          </a>
        </li>
        <li className="hover:scale-150 transition-transform duration-300 ease-out">
          <a href={twitterLink} target="_blank" rel='noreferrer'>
            <img src={TwitterIcon} alt="twitter" />
          </a>
        </li>
        <li className="hover:scale-150 transition-transform duration-300 ease-out">
          <a href={githubLink} target="_blank" rel='noreferrer'>
            <img src={GithubIcon} alt="github" />
          </a>
        </li>
      </ul>
    </div>
  );
};
