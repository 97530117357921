import { useLocation } from 'react-router-dom';
import { SelectSwitcher } from '../generic/switcher/SelectSwitcher';
import { useState } from 'react';
import { ButtonSwitcher } from '../generic/switcher/ButtonSwitcher';
import { CaseList } from './CaseList';

const switchPositionList = [
  { id: '0', value: '', label: 'Type of applications', placeholder: true },
  { id: '1', value: 'adalo', label: 'Adalo' },
  { id: '2', value: 'uniqueApplications', label: 'Unique Applications' },
  { id: '3', value: 'popularApps', label: 'Popular apps' },
];

const getTabTypeFromstring = (option: string) => {
  switch (option) {
    case 'popularApps':
      return 'popularApps';
    case 'uniqueApplications':
      return 'uniqueApplications';
    case 'adalo':
      return 'adalo';
    default:
      return 'adalo';
  }
};

const labels = {
  left: {
    title: 'Adalo',
    value: 'adalo',
  },
  center: {
    title: 'Unique Applications',
    value: 'uniqueApplications',
  },
  right: {
    title: 'Popular apps',
    value: 'popularApps',
  },
};

export const TypesOfApplications = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const typeofapplications = queryParams.get('typeofapplications');
  const [switchPosition, setSwitchPosition] = useState<string>(
    typeofapplications !== null
      ? getTabTypeFromstring(typeofapplications)
      : 'adalo'
  );
  return (
    <div className="pb-16">
      <div className="flex justify-center">
        <SelectSwitcher
          switchPosition={switchPosition}
          setSwitchPosition={setSwitchPosition}
          switchPositionList={switchPositionList}
        />
        <ButtonSwitcher
          switchPosition={switchPosition}
          setSwitchPosition={setSwitchPosition}
          labels={labels}
          searchWord="typeofapplications"
        />
      </div>
      <CaseList />
    </div>
  );
};
