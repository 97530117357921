import { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import styles from './ButtonSwitcher.module.css';

interface ILabels {
  left: { title: string; value: string };
  center: { title: string; value: string };
  right: { title: string; value: string };
}

interface IButtonSwitcherProps {
  switchPosition: string;
  setSwitchPosition: (switchPosition: string) => void;
  labels: ILabels;
  searchWord: string;
}

export const ButtonSwitcher = ({
  switchPosition,
  setSwitchPosition,
  labels,
  searchWord,
}: IButtonSwitcherProps) => {
  const [animation, setAnimation] = useState<string>('startup');
  const location = useLocation();
  

  const getSwitchAnimation = (newPosition: string, currentPosition: string) => {
    if (
      newPosition === labels.center.value &&
      currentPosition === labels.left.value
    ) {
      return 'startup_to_smallAndMedium';
    } else if (
      newPosition === labels.right.value &&
      currentPosition === labels.center.value
    ) {
      return 'smallAndMedium_to_enterprises';
    } else if (
      newPosition === labels.center.value &&
      currentPosition === labels.right.value
    ) {
      return 'enterprises_to_smallAndMedium';
    } else if (
      newPosition === labels.left.value &&
      currentPosition === labels.center.value
    ) {
      return 'smallAndMedium_to_startup';
    } else if (
      newPosition === labels.right.value &&
      currentPosition === labels.left.value
    ) {
      return 'startup_to_enterprises';
    } else if (
      newPosition === labels.left.value &&
      currentPosition === labels.right.value
    ) {
      return 'enterprises_to_startup';
    }
    return 'startup_to_smallAndMedium';
  };

  const setSwitchAnimation = (value: string) => {
    const animationClass = getSwitchAnimation(value, switchPosition);
    setAnimation(animationClass);
    setSwitchPosition(value);
  };

  const onTagClick = (option: string) => {
    setSwitchPosition(option);
    setSwitchAnimation(option);
  };

  return (
    <div className="flex justify-center">
      <div className="relative hidden lg:inline-block align-middle w-[723px] h-[50px] rounded-3xl bg-cosmith-primary-50">
        <div
          className={`${styles.switch}  ${styles[animation]} ${
            styles[`${switchPosition}_position`]
          }`}
        ></div>
        <NavLink
          to={{
            pathname: location.pathname,
            search: `?${searchWord}=${labels.left.value}`,
          }}
          className={`${styles.label} ${styles.startup_label} ${
            switchPosition === 'startup' && styles.label_checked
          }`}
          onClick={() => onTagClick('startup')}
        >
          <p className="font-medium leading-6 tracking-[-0.01rem]">
            {labels.left.title}
          </p>
        </NavLink>
        <NavLink
          to={{
            pathname: location.pathname,
            search: `?${searchWord}=${labels.center.value}`,
          }}
          className={`${styles.label} ${styles.smallAndMedium_label} ${
            switchPosition === 'smallAndMedium' && styles.label_checked
          }`}
          onClick={() => onTagClick('smallAndMedium')}
        >
          <p className="font-medium leading-6 tracking-[-0.01rem]">
            {labels.center.title}
          </p>
        </NavLink>

        <NavLink
          to={{
            pathname: location.pathname,
            search: `?${searchWord}=${labels.right.value}`,
          }}
          className={`${styles.label} ${styles.enterprises_label} ${
            switchPosition === 'enterprises' && styles.label_checked
          }`}
          onClick={() => onTagClick('enterprises')}
        >
          <p className="font-medium leading-6 tracking-[-0.01rem]">
            {labels.right.title}
          </p>
        </NavLink>
      </div>
    </div>
  );
};
