import { Outlet } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { loadingState } from '../state';

import bgImg from '../assets/auth/auth-bg.png';

import { Preloader } from '../components/generic';

export const AuthLayout = () => {
  const loading = useRecoilValue(loadingState);

  return (
    <div
      className="h-screen bg-right bg-no-repeat"
      style={{ backgroundImage: `url(${bgImg})` }}
    >
      <div className="h-full grid grid-rows-[64px_1fr]">
        <Outlet />
      </div>

      <Preloader visible={loading} />
    </div>
  );
};
