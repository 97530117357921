import { Routes } from '../../../../util/constants';
import { NavLinkButton } from '../../../generic';

type LoginProps = {
  isSignupVisible: boolean;
};

export const Login = ({ isSignupVisible }: LoginProps) => {
  return (
    <div className="flex lg:gap-3 xl:gap-4 xl:pl-3">
      <NavLinkButton
        to={Routes.login()}
        type="link"
        size="extraSmall"
        className="hidden lg:flex"
      >
        Login
      </NavLinkButton>
      <NavLinkButton
        to={Routes.register()}
        type="dark"
        size="extraSmall"
        className={`text-sm font-medium ${isSignupVisible && 'hidden'}`}
      >
        Signup
      </NavLinkButton>
    </div>
  );
};
