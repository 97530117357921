import { SetterOrUpdater } from 'recoil';
import { Select, SelectOptions } from '../generic/Select';

interface Props {
  value: string;
  onChange: SetterOrUpdater<string>;
  className?: string;
}

export default function ProductCategoryFilter ({ value, onChange, className = '' }: Props): JSX.Element {
  const categoryList: SelectOptions[] = [
    { id: '0', value: '',                       label: 'App categories', placeholder: true },
    { id: '1', value: 'Recommended',            label: 'Recommended' },
    { id: '2', value: 'Developer tools',        label: 'Developer tools' },
    { id: '3', value: 'Communication',          label: 'Communication' },
    { id: '4', value: 'Website & App Building', label: 'Website & App Building' },
  ];

  return (
    <Select
      className={className}
      label="App Categories"
      value={value}
      options={categoryList}
      onChange={onChange}
    />
  );
}
