import { Breadcrumbs } from '../components/layout';
import { Preloader } from '../components/generic';
import ProductFilterSection from '../components/products/ProductFilterSection';
import ProductListWrapper from '../components/products/ProductListWrapper';
import ProductCard from '../components/products/ProductCard';
import { useProductList } from '../hooks';

export const CategoriesPage = () => {
  const { products, loading } = useProductList();

  const crumbs = [
    { label: 'Category' }
  ];

  return (
    <>
      <Breadcrumbs crumbs={crumbs} />

      <div className="h-fit flex-grow md:overflow-y-auto px-4 sm:px-3">
        <ProductFilterSection />

        <Preloader visible={loading} />

        <ProductListWrapper>
          {products && products.map((product) => (
            <ProductCard key={product.id} product={product} />
          ))}
        </ProductListWrapper>
      </div>
    </>
  );
};
