import { Breadcrumbs } from '../components/layout';
import { Button, Preloader } from '../components/generic';
import MyComponentListWrapper from '../components/my-components/MyComponentListWrapper';
import MyComponentCard from '../components/my-components/MyComponentCard';
import MyComponentCardSmall from '../components/my-components/MyComponentCardSmall';
import { useMyComponentList, useScreenSize } from '../hooks';
import { MyComponentEmpty } from '../components/my-components/MyComponentEmpty';

export const MyComponentsPage = () => {
  const screen = useScreenSize();
  const { components, loading } = useMyComponentList();
  const crumbs = [{ label: 'My components' }];

  return (
    <>
      <Breadcrumbs crumbs={crumbs} />

      <div className="h-fit flex-grow md:overflow-y-auto px-4 pb-6 sm:px-3">
        <Preloader visible={loading} />

        {components.length > 0 ? (
          <>
            <MyComponentListWrapper>
              {components.map((component) =>
                screen.gt.md
                  ? <MyComponentCard key={component.id} component={component} />
                  : <MyComponentCardSmall key={component.id} component={component} />
              )}
            </MyComponentListWrapper>

            <Button className="mt-6">Add component (1)</Button>
          </>
        ) : (
            <MyComponentEmpty loading={ loading} />
        )}
      </div>
    </>
  );
};
