import {
  FormControl,
  FormHint,
  FormInput,
  FormLabel,
  FormHeader,
  FormDescription,
  Button,
  FormCheckbox,
  FormTextArea,
} from '../generic';

import { useHelpExpertForm } from '../../hooks/ui/useHelpExpertForm';

export const HelpForm = () => {
  const { name, email, subscribe, message, onSubmit } = useHelpExpertForm();

  return (
    <div
      className="reletive flex items-center justify-end
                          after:content-[' '] after:h-[358px] after:w-[358px]
                          after:-z-10 after:absolute after:bg-[url('/src/assets/BackgroundRight.svg')] after:bg-no-repeat after:right-0
                          sm:after:h-[588px] sm:after:w-[588px]
                          lg:after:bg-none
                          xl:after:right-0
                          xl:after:bg-[url('/src/assets/BackgroundRight.svg')]
                          after:bg-left "
    >
      <div className="w-full sm:w-[356px] mt-10 lg:mt-0">
        <FormHeader text="Help" />
        <FormDescription>
          If you have any questions, write to us!
        </FormDescription>

        <form onSubmit={(e) => e.preventDefault()} className="w-full space-y-6">
          <FormControl variant={name.state.variant} className="relative">
            <FormLabel htmlFor="name" children="Name" />
            <FormInput
              id="name"
              placeholder="Enter your name"
              {...name.register}
            />
            <FormHint hint={name.state.message} className="absolute " />
          </FormControl>

          <FormControl variant={email.state.variant} className="relative">
            <FormLabel htmlFor="email" children="Email" />
            <FormInput
              id="email"
              placeholder="Enter your email"
              {...email.register}
            />
            <FormHint
              hint={email.state.message}
              className="absolute bottom-[-20px]"
            />
          </FormControl>

          <FormControl variant={message.state.variant} className="relative">
            <FormLabel htmlFor="message" children="Message" />
            <FormTextArea
              id="message"
              placeholder="Explain your needs"
              className="h-[120px resize-none"
              {...message.register}
            />
            <FormHint
              hint={message.state.message}
              className="absolute bottom-[-20px]"
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="subscribe" children="" />
            <div className="flex justify-between items-center">
              <FormCheckbox
                id="subscribe"
                label="Subscribe to the cosmith.io blog email"
                {...subscribe.register}
              />
            </div>
          </FormControl>

          <Button className="w-full" type="dark" onClick={onSubmit}>
            Continue
          </Button>
        </form>
      </div>
    </div>
  );
};
