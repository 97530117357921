import { Loader } from '../../components/generic/Loader';
import ProductListWrapper from '../../components/products/ProductListWrapper';
import { ProductCardUniversal as ProductCard } from '../products/ProductCardUniversal';
import { useProductByCategoriesList } from '../../hooks';
import { FilterByCategoriesButtons } from './FilterByCategoriesButtons';
import { ShowMoreButtons } from './ShowMoreButtons';
import { ErrorSection } from './ErrorSection';
import { CallToActionDescription } from '../generic/landing/CallToActionDescription';
import { SectionSubTitle } from '../generic/landing/SectionSubTitle';
import { shownProductsPerPage } from '../../appconfig';
import { EmptyCategories } from './EmptyCategories';
import { useAnchor } from '../generic/useAnchor';
import { GenericListWrapper } from '../generic/landing/GenericListWrapper';

const options = [
  { value: 'top', label: 'TOP' },
  { value: 'viewers', label: 'Viewers' },
  { value: 'social', label: 'Social' },
  { value: 'unique', label: 'Unique' },
];

export const ProductListSection = () => {
  const { products, loading, error, loadMore, showDefaultQuantity, allShown, allProducts } =
    useProductByCategoriesList();
  const { anchor, scrollToAnchor } = useAnchor();
  const loadMoreComponents = () => loadMore(shownProductsPerPage);
  const showDefaultComponents = () => {
    showDefaultQuantity();
    scrollToAnchor();
  };

  return (
    <section className="h-fit flex-grow md:overflow-y-auto px-4 sm:px-3">
      {anchor}
      <div className="text-center flex flex-col items-center mb-6 lg:mb-16">
        <SectionSubTitle>Explore the items you&#39;ll like</SectionSubTitle>
        <CallToActionDescription />
      </div>
      <FilterByCategoriesButtons options={options} />
      <GenericListWrapper
        error={error}
        errorComponent={<ErrorSection />}
        loadingComponent={<Loader />}
        loading={loading}
      >
        <div className='lg:min-h-[780px]'>
          {products.length ? (
            <div className='flex flex-col items-center sm:block'>
              <ProductListWrapper className="lg:grid-cols-2">
                {products?.map((product) => (
                  <ProductCard key={product.id} product={product}/>
                ))}
              </ProductListWrapper>
              {allProducts.length > shownProductsPerPage && (
                <ShowMoreButtons
                  loadMore={loadMoreComponents}
                  resetToDefault={showDefaultComponents}
                  allShown={allShown}
                />
              )}
            </div>
          ) : (
            <EmptyCategories />
          )}
        </div>
      </GenericListWrapper>
    </section>
  );
};
