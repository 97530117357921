import { AdaptiveImageCard } from '../generic/AdaptiveImageCard';
import { ResponsivePicture } from '../generic/ResponsivePicture';
import { DottedWrapperHero } from '../generic/landing/DottedWrapperHero';
import { HeroText } from '../generic/landing/HeroText';
import { HeroTitle } from '../generic/landing/HeroTitle';

import Placeholder_mob from '../../assets/user-case/Placeholder_mob.png';
import Placeholder_tablet from '../../assets/user-case/Placeholder_tablet.png';
import Placeholder_pc from '../../assets/user-case/Placeholder_pc.png';
import { TalkToAnExpertButton } from '../generic/landing/TalkToAnExpertButton';
import { NavLinkButton } from '../generic';

const images = {
  mobile: {
    src: Placeholder_mob,
    width: 358,
  },
  tablet: {
    src: Placeholder_tablet,
    width: 500,
  },
  pc: {
    src: Placeholder_pc,
    width: 588,
  },
};

export const NoCodePlatformUsageSection = () => {
  return (
    <DottedWrapperHero>
      <div className="text-center lg:text-left">
        <AdaptiveImageCard
          className="py-12"
          secondChildrenclassName="lg:w-[500px] lg:h-[550px] xl:w-588px xl:h-[588px]"
          secondChildren={
            <ResponsivePicture imageUrl={Placeholder_mob} images={images} />
          }
        >
          <div className="mb-6">
            <HeroTitle>Why do I use the no code platform?...</HeroTitle>
            <div>
              <HeroText>
                This white paper provides a comprehensive overview of how AI and
                its subfield, machine learning, shape the current trends in web
                scraping.
              </HeroText>
            </div>
            <div className="flex items-center flex-col gap-[0.75rem] mt-6 lg:flex-row lg:gap-4 lg:mt-8">
              <NavLinkButton
                to="/category"
                type="dark"
                size="normal"
                className="min-w-[19rem] max-w-[25rem] lg:min-w-[9.75rem] lg:max-w-[9.75rem]"
              >
                Get started
              </NavLinkButton>
              <TalkToAnExpertButton />
            </div>
          </div>
        </AdaptiveImageCard>
      </div>
    </DottedWrapperHero>
  );
};
