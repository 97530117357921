import { NavLinkButton } from '../generic';
import { SectionSubTitle } from '../generic/landing/SectionSubTitle';
import { SectionMainText } from '../generic/landing/SectionMainText';
import { ReviewCard } from './ReviewCard';
import { AdaptiveImageCard } from '../generic/AdaptiveImageCard';

export const TrustedBySection = () => {
  return (
    <div className="py-6 lg:py-0">
      <AdaptiveImageCard secondChildren={<ReviewCard />} reversed={true}>
        <SectionSubTitle className="text-center mb-6 lg:text-left">
          Trusted by top companies
        </SectionSubTitle>
        <SectionMainText className="mb-6 max-w-[700px]">
          Forward-thinking businesses, from startups to the largest companies in
          the world, trust сosmith.io for its top-quality components and no-code
          solutions.
        </SectionMainText>
        <div className="flex justify-center lg:justify-start">
          <NavLinkButton
            to={'/user-case'}
            type="primary"
            className="min-w-[19rem] max-w-[25rem] lg:min-w-[11.375rem] lg:max-w-[11.375rem]"
          >
            See success stories
          </NavLinkButton>
        </div>
      </AdaptiveImageCard>
    </div>
  );
};
