import { NavLink, useLocation } from 'react-router-dom';

interface IListElem {
  id: string;
  label: string;
  placeholder?: boolean;
  value: string;
}

interface ISidebarProps {
  categoryList: IListElem[];
}

export const Sidebar = ({ categoryList }: ISidebarProps) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const categoryValue = searchParams.get('category');
  const searchValue = searchParams.get('search');

  return (
    <div className="hidden xl:block xl:ml-[-214px]">
      <div className="w-[214px]">
        <ul>
          {categoryList.map(({ placeholder, value, id, label }) => (
            <li
              key={id}
              className={`${
                categoryValue === value
                  ? 'bg-cosmith-primary-50 transition-colors duration-700 ease-in-out py-2.5 px-4'
                  : 'bg-white transition-colors  duration-700 ease-in-out py-2.5 px-4'
              }`}
            >
              <NavLink
                className={`${
                  placeholder &&
                  'text-cosmith-primary-900/60 text-sm cursor-default'
                }`}
                to={
                  placeholder
                    ? searchValue
                      ? `?search=${searchValue}`
                      : ''
                    : searchValue
                      ? `?category=${value}&search=${searchValue}`
                      : `?category=${value}`
                }
              >
                {label}
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
