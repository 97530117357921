import { LogoutIcon } from '../../../../icons';
import { MainLogo } from '../../../../icons/Logo';
import { Burger, useNavMenu } from '../../../generic/burger/Burger';
import { BurgerMenu } from '../../../generic/burger/BurgerMenu';
import { Login } from './Login';
import { Navigation } from './Navigation';
import { NavLink, useNavigate } from 'react-router-dom';

import { Routes } from '../../../../util/constants';
import { useIsUserAuth } from '../../../../hooks/auth/useIsUserAuth';
import { useContext } from 'react';
import { AccountContext } from '../../../../contexts/AccountContext';

export const Navbar = () => {
  const { isNavMenuOpen, toggleNavMenu } = useNavMenu();
  const navigate = useNavigate();
  const account = useContext(AccountContext);
  const isUserAuth = useIsUserAuth();

  const onAvatarClick = () => {
    //TODO: change navigation to My settings page when this page is ready
    navigate(Routes.myComponents());
  };

  const onLogoutClick = () => {
    navigate(Routes.logout(), { replace: true });
  };

  return (
    <div className="sm:h-full md:h-auto w-full px-4 lg:px-8 xl:px-32 max-w-[1920px] m-auto">

      <div className="py-4 flex items-center justify-between lg:py-2.5 lg:text-sm xl:py-4 xl:text-base	">
        <NavLink to={'/'}>
          <MainLogo type="black" />
        </NavLink>
        <Navigation />
        <div className="flex gap-4 items-center lg:ml-1 xl:ml-0">
          {!isUserAuth ? (
            <Login isSignupVisible={isNavMenuOpen} />
          ) : (
            <div className="flex justify-between">
              <button
                type="button"
                className="ml-4 cursor-auto"
                onClick={() => onAvatarClick()}
              >
                <img
                  className="inline-block h-8 w-8 rounded-full ring-2 ring-white border cursor-pointer"
                  src={account?.photo}
                  alt="avatar"
                />
              </button>
              <button
                type="button"
                className="ml-4"
                onClick={() => onLogoutClick()}
              >
                <LogoutIcon />
              </button>
            </div>
          )}
          <Burger onBurgerClick={toggleNavMenu} isMenuOpen={isNavMenuOpen} />
        </div>
      </div>
      <div className="relative lg:hidden">
        <BurgerMenu
          isBurgerMenuVisible={isNavMenuOpen}
          onOptionClick={toggleNavMenu}
        />
      </div>
    </div>
  );
};
