import { Link, useLocation } from 'react-router-dom';
import { ChevronRightIcon } from '@heroicons/react/20/solid';

interface Crumb {
  label: string;
  to?: string;
}

interface Props {
  crumbs: Crumb[];
}

export function Breadcrumbs ({ crumbs }: Props): JSX.Element {
  const location = useLocation();
  const isMyPage = location.pathname.includes('my');

  const crumbClassName = (isEmpty: boolean): string => {
    const baseClasses = 'grid grid-cols-[24px_1fr] items-center text-cosmith-primary-900/60 whitespace-nowrap';

    return isEmpty
      ? baseClasses
      : `${baseClasses} hover:text-cosmith-primary-900 transition-colors cursor-pointer`;
  };

  return (
    <ol className="flex py-6 px-6 gap-x-5 justify-center pt-2 sm:justify-start sm:pt-6">
      {crumbs.map((crumb, index) => {
        return (
          <li key={index} className={crumbClassName(!crumb.to)}>
            {isMyPage ? (
              <ChevronRightIcon className="fill-current h-3 w-3 -ml-1" />
            ) : index? <ChevronRightIcon className="fill-current h-3 w-3 -ml-1" /> : <div></div> }
            {!crumb.to ? (
              <span>{crumb.label}</span>
            ) : (
              <Link to={crumb.to}>{crumb.label}</Link>
            )}
          </li>
        );
      })}
    </ol>
  );
}
