import { NavLink, useLocation } from 'react-router-dom';
import {
  // BillingIcon,
  CategoryIcon,
  ComponentsIcon,
  CosmithIcon,
  CosmithIoLabel,
  // HelpIcon,
  // SettingsIcon,
  TalkToExpertIcon,
} from '../../icons';
import { Dispatch, SetStateAction } from 'react';

interface Props {
  isMobile: boolean;
  setShowSidebar: Dispatch<SetStateAction<boolean>>;
}

interface Link {
  slug: string;
  title: string;
  icon: (className: string) => JSX.Element;
}

export const SideBar = ({ isMobile, setShowSidebar }: Props) => {
  const { pathname } = useLocation();

  const links: Link[] = [
    {
      slug: 'components',
      title: 'My components',
      icon: (className: string) => <ComponentsIcon className={className} />,
    },
    {
      slug: 'category',
      title: 'Category',
      icon: (className: string) => <CategoryIcon className={className} />,
    },
    {
      slug: 'talk-to-expert',
      title: 'Talk to expert',
      icon: (className: string) => <TalkToExpertIcon className={className} />,
    },
    // {
    //   slug: 'billing',
    //   title: 'Billing',
    //   icon: (className: string) => <BillingIcon className={className} />,
    // },
    // {
    //   slug: 'setting',
    //   title: 'Setting',
    //   icon: (className: string) => <SettingsIcon className={className} />,
    // },
    // {
    //   slug: 'help',
    //   title: 'Help',
    //   icon: (className: string) => <HelpIcon className={className} />,
    // },
  ];

  const isActiveLink = (slug: string) => {
    return pathname.includes(`/${slug}`);
  };

  return (
    <div
      className={`${
        isMobile
          ? 'h-128 w-full'
          : 'h-screen w-[11.625rem] xl:w-[13.5rem] fixed'
      } shadow-lg bg-cosmith-primary-900`}
    >
      <div className="flex items-center h-16 px-4">
        <NavLink className="flex items-center space-x-2" to="/">
          <CosmithIcon />
          <CosmithIoLabel />
        </NavLink>
      </div>
      <nav className="flex-grow overflow-y-auto mt-[75px]">
        <ul>
          {links.map((link) => (
            <li
              key={link.slug}
              className="h-[60px] text-base text-zinc-400 hover:bg-cosmith-primary-50/10"
              onClick={() => {
                isMobile && setShowSidebar(false);
              }}
            >
              <NavLink
                to={`my/${link.slug}`}
                className={({ isActive }) =>
                  `w-full h-full flex items-center font-medium gap-1 px-4 ${
                    isActive ? 'text-white' : ''
                  }`
                }
              >
                {link.icon(
                  isActiveLink(link.slug) ? 'fill-white' : 'fill-zinc-400'
                )}
                &nbsp;
                {link.title}
              </NavLink>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};
