import { FormControl, FormHint, FormInput, FormLabel, FormHeader, FormDescription, Button } from '../../generic';
import { NavLink } from 'react-router-dom';
import { GoogleIcon } from '../../../icons';
import { useRegisterForm } from '../../../hooks/auth/useRegisterForm';
import { Routes } from '../../../util/constants';

export function RegisterForm () {
  const { name, email, password, onSubmit, onRegisterWithGoogle } = useRegisterForm();

  return (
    <div className='w-full sm:w-[356px]'>
      <FormHeader text="Get started" />
      <FormDescription>
        Already have an account?
        <NavLink to={Routes.login()} className="font-semibold mx-2">Log in</NavLink>
      </FormDescription>

      <form onSubmit={(e) => e.preventDefault()} className='w-full space-y-6'>
        <FormControl variant={name.state.variant}>
          <FormLabel htmlFor='name' children="Name" />
          <FormInput id="name" placeholder="Enter your name" {...name.register} />
          <FormHint hint={name.state.message} className='absolute'/>
        </FormControl>

        <FormControl variant={email.state.variant}>
          <FormLabel htmlFor='email' children="Email" />
          <FormInput id="email" placeholder="Enter your email" {...email.register} />
          <FormHint hint={email.state.message} className='absolute'/>
        </FormControl>

        <FormControl variant={password.state.variant} className='relative'>
          <FormLabel htmlFor='password' children="Password" />
          <FormInput id="password" placeholder="Enter your password" type="password" {...password.register} />
          <FormHint hint={password.state.message} className='absolute -bottom-5'/>
        </FormControl>

        <Button
          className="w-full"
          type="dark"
          onClick={onSubmit}
        >
          Create account
        </Button>
      </form>

      <p className="text-center my-6">or sign up with</p>

      <Button className="w-full" onClick={() => onRegisterWithGoogle()}>
        <GoogleIcon />
        <span className="ml-2">Sign up with Google</span>
      </Button>
    </div>
  );
}
