export class Routes {
  // auth
  static login = () => '/login';
  static logout = () => '/logout';
  static register = () => '/sign-up';
  static resetPassword = () => '/reset/password';
  static confirmEmail = (confirmId = ':confirmId') => `/confirm-email/${confirmId}`;
  static waitingEmailConfirmation = () => '/wait-for-confirmation-email';
  static category = (searchTerm?: string) => searchTerm ? `/category?search=${searchTerm}`: '/category';
  static solutions = (searchTerm?: string) => searchTerm ? `/solutions?search=${searchTerm}`: '/solutions';
  static talkToExpert = () => '/talk-to-expert';
  static userCase = () => '/user-case';
  static help = () => '/help';
  static openCategoryProduct = (productName = ':productName') => `/category/${productName}`;
  static openCategoryProductPricing = (productName = ':productName') => `/category/${productName}/pricing`;
  static openCategoryProductOverview = (productName = ':productName') => `/category/${productName}/overview`;
  static openCategoryProductInstallation = (productName = ':productName') => `/category/${productName}/installation`;
  static openCategoryProductCases = (productName = ':productName') => `/category/${productName}/cases`;
  static openCategoryProductVersions = (productName = ':productName') => `/category/${productName}/versions`;

  // private
  static myComponents = () => '/my/components';
  static myCategory = () => '/my/category';
  static mySettings = () => '/my/settings';
  static myTalkToAnExpert = () => '/my/talk-to-expert';

  static myCategoryProduct = (productName = ':productName') => `/my/category/${productName}`;
  static myCategoryProductPricing = (productName = ':productName') => `/my/category/${productName}/pricing`;
  static myCategoryProductOverview = (productName = ':productName') => `/my/category/${productName}/overview`;
  static myCategoryProductInstallation = (productName = ':productName') => `/my/category/${productName}/installation`;
  static myCategoryProductCases = (productName = ':productName') => `/my/category/${productName}/cases`;
  static myCategoryProductVersions = (productName = ':productName') => `/my/category/${productName}/versions`;
}
