import ReactMarkdown from 'react-markdown';
import { ArrowRight } from '../../assets/how-its-work/ArrowRight';
import { NavLinkButton } from '../generic';
import remarkGfm from 'remark-gfm';

interface ICaseProps {
  case: {
    image: string;
    title: string;
    shortDescription: string;
    productName: string;
    publishDate: string;
    id: string;
  };
}

export const CaseCard = ({
  case: { image, title, shortDescription, publishDate, productName, id },
}: ICaseProps) => {
  return (
    <li className="rounded-[32px] border-solid border border-cosmith-primary-900/10 p-6 lg:p-12">
      <img
        src={image}
        alt={title}
        className="w-12 h-12 mx-auto mb-8 rounded-lg lg:w-32 lg:h-32 lg:mx-0 "
      />
      <h2 className="text-center font-semibold text-xl tracking-[-0.28px] mb-4 lg:text-start lg:text-2xl lg:tracking-[-0.384px]">
        {title}
      </h2>
      <div className="font-semibold text-base leading-6 tracking-[-0.16px] flex flex-col gap-4 mb-4">
        <p>for {productName}</p>
        <p className="font-medium">{publishDate}</p>
      </div>
      <ReactMarkdown
        className="prose-m prose-ul:list-disc prose-ol:list-decima prose-a:underline underline-offset-2 text-cosmith-primary-900/60 font-normal leading-6 tracking-[-0.16px]"
        children={shortDescription}
        remarkPlugins={[remarkGfm]}
      />
      <NavLinkButton
        type="text"
        size="noSize"
        to={`/user-case/${id}`}
        className="mt-6 group w-fit tap-highlight-transparent"
      >
        Read more
        <ArrowRight className="fill-cosmith-primary-900/60 group-hover:fill-cosmith-primary-900" />
      </NavLinkButton>
    </li>
  );
};
