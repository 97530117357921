import { ReactComponent as ArrowDown } from '../../icons/ArrowDownSmall.svg';
import { ReactComponent as ArrowUp } from '../../icons/ArrowUpSmall.svg';
import { useState } from 'react';
import { Paragraphs } from './Paragraphs';

const content = [
  {
    title: 'Creativity with Cosmith.io',
    paragraphs: [
      'We provide excellent technical support to ensure that your applications work seamlessly with our components. With Cosmith.io, you dont need any coding expertise to create amazing applications.',
      'Our team of experts is available to help you achieve your business goals and guide you through the development process.',
      'Our team of experts is available to help you achieve your business goals',
      'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Doloribus, ea nulla! Voluptatem maxime, ipsam reiciendis autem, obcaecati aliquam quam dolorem neque deserunt sit dolorum expedita harum, error officia! Maxime, quis.',
    ],
  },
  {
    title: 'Cosmith.io',
    paragraphs: [
      'We provide excellent technical support. With Cosmith.io, you dont need any coding expertise to create amazing applications.',
      'Our team of experts is available to help you achieve.',
    ],
  },
  {
    title: 'Adalo',
    paragraphs: [
      'We provide excellent technical support. With Cosmith.io, you dont need any coding expertise to create amazing applications.',
      'Our team of experts is available to help you achieve.',
      'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Doloribus, ea nulla! Voluptatem maxime, ipsam reiciendis autem, obcaecati aliquam quam dolorem neque deserunt sit dolorum expedita harum, error officia! Maxime, quis.',
    ],
  },
];

export const FaqSection = () => {
  const [titles, setTitles] = useState<string[]>([]);

  const ArrowIcon = (title: string) =>
    titles?.includes(title) ? (
      <ArrowDown className="absolute top-1/2 transform -translate-y-1/2" />
    ) : (
      <ArrowUp className="absolute top-1/2 transform -translate-y-1/2" />
    );

  const toggle = (title: string) => {
    setTitles((prev) => {
      const index = prev.indexOf(title);

      if (index !== -1) {
        return prev.filter((prevTitle) => title !== prevTitle);
      } else {
        return [...prev, title];
      }
    });
  };

  return (
    <div className="py-12 lg:py-16">
      <h2
        className="text-4xl text-center font-semibold leading-[1.17] tracking-[-0.041rem] 
      lg:text-5xl lg:leading-[1.125] lg:tracking-[-0.06rem] "
      >
        Frequently asked questions
      </h2>
      <ul className="py-6 lg:py-16 max-w-3xl mx-auto">
        {content.map(({ title, paragraphs }, idx) => (
          <li key={title} className={`py-6 ${!idx ? 'border-y' : 'border-b'}`}>
            <div
              className="relative cursor-pointer"
              onClick={() => toggle(title)}
            >
              <h2
                className="text-lg font-medium leading-6 tracking-tight text-center
                 lg:text-2xl lg:text-left lg:font-semibold lg:leading-8 lg:tracking-tighter lg:ml-6"
              >
                {title}
              </h2>
              {ArrowIcon(title)}
            </div>

            {titles.includes(title) && <Paragraphs paragraphs={paragraphs} />}
          </li>
        ))}
      </ul>
    </div>
  );
};
