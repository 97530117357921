import { RelatedCategories } from './RelatedCategories';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

interface ICaseArticle {
  userCase: { image: string; description: string; relatedCategories: string[] };
}

export const UserCaseArticleContent = ({ userCase }: ICaseArticle) => {
  const { relatedCategories } = userCase;
  return (
    <div className="mb-32">
      <div className="flex flex-col lg:flex-col-reverse">
        <RelatedCategories relatedCategories={relatedCategories} />
        <img
          src={userCase.image}
          alt="imageCase"
          className="my-6 lg:w-[584px] lg:mt-0"
        />
      </div>
      <ReactMarkdown
        className="prose  max-w-full lg:max-w-[70%]  prose-m prose-ul:list-disc prose-ol:list-decima prose-a:underline underline-offset-2 text-cosmith-primary-900/60 text-justify font-medium leading-6 tracking-[-0.16px]"
        children={userCase.description}
        remarkPlugins={[remarkGfm]}
      />
    </div>
  );
};
