import { NavbarList } from './NavbarList';
import { Routes } from '../../../../util/constants';

const footerLists = [
  {
    title: 'Products',
    links: [
      { slug: Routes.solutions(), title: 'For any size company' },
      { slug: Routes.talkToExpert(), title: 'Hire an Expert' },
      { slug: Routes.userCase(), title: 'User cases' },
    ],
  },
  {
    title: 'Categories',
    links: [
      { slug: Routes.category(), title: 'Recommended' },
      { slug: Routes.category(), title: 'Developer tools' },
      { slug: Routes.category(), title: 'Communication' },
      { slug: Routes.category(), title: 'Content & Files' },
      { slug: Routes.category(), title: 'Web site & App Building' },
      { slug: Routes.category(), title: 'Marketing' },
      { slug: Routes.category(), title: 'Sales & CRM' },
    ],
  },
  {
    title: 'Company',
    links: [
      { slug: '/', title: 'About us' },
      { slug: 'contact', title: 'Contact us' },
      { slug: Routes.help(), title: 'Help Center' },
    ],
  },

  {
    title: 'Info',
    links: [
      // { slug: 'license', title: 'License' },
      // { slug: 'cookies', title: 'Cookies' },
      { slug: 'terms', title: 'Terms of use' },
      { slug: 'privacy', title: 'Privacy' },
    ],
  },
];

export const FooterNavbar = () => {
  return (
    <ul className="flex justify-between flex-wrap gap-x-4 gap-y-8 lg:justify-normal lg:gap-x-6 lg:gap-y-0">
      {footerLists.map(({ title, links }) => (
        <li key={title}>
          <NavbarList title={title} links={links} />
        </li>
      ))}
    </ul>
  );
};
