import { NavLinkButton } from '../../../generic';

export const Navigation = () => {
  return (
    <nav className="hidden lg:block grow border-r lg:pr-1 xl:pr-3">
      <ul className="flex justify-between">
        <div className="flex gap-1">
          <li>
            <NavLinkButton to="/category" type="link" size="extraSmall">
              Category
            </NavLinkButton>
          </li>
          <li>
            <NavLinkButton to="/solutions" type="link" size="extraSmall">
              Solutions
            </NavLinkButton>
          </li>
          <li>
            <NavLinkButton to="/user-case" type="link" size="extraSmall">
              User case
            </NavLinkButton>
          </li>
          <li>
            <NavLinkButton to="/work" type="link" size="extraSmall">
              How it works?
            </NavLinkButton>
          </li>
        </div>
        <div>
          <li>
            <NavLinkButton to="/talk-to-expert" type="link" size="extraSmall">
              Talk to an expert
            </NavLinkButton>
          </li>
        </div>
      </ul>
    </nav>
  );
};
