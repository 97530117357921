import { SetterOrUpdater } from 'recoil';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { ChangeEvent, useRef, useState } from 'react';

type ValueType = string;

type OnChangeHandlerFn = (value: ValueType) => void;
type OnChangeHandlerSetter = SetterOrUpdater<ValueType>;

interface Props {
  placeholder?: string;
  value: ValueType;
  onChange: OnChangeHandlerFn | OnChangeHandlerSetter;
  className?: string;
}

export function Search ({
  value,
  onChange,
  className = '',
  placeholder = 'Search'
}: Props): JSX.Element {
  const input = useRef<HTMLInputElement>(null);
  const [focus, setFocus] = useState(false);

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  const onClickHandler = () => {
    input.current?.focus();
  };

  return (
    <div className={className}>
      <div
        className="relative flex border-solid border border-cosmith-primary-900/10 rounded-lg shadow py-3 pr-3 pl-12 h-12"
        onClick={onClickHandler}
      >
        <MagnifyingGlassIcon className="absolute top-2.5 left-3.5 h-7 w-7 text-cosmith-primary-900/60 pr-2" />
        <input
          className="w-full outline-none"
          ref={input}
          type="text"
          value={value}
          onChange={onChangeHandler}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
        />
        {!value && !focus
          ? <span className="absolute text-cosmith-primary-900/60">{placeholder}</span>
          : null
        }
      </div>
    </div>
  );
}
