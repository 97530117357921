import Container_mob from '../../../assets/how-its-work/Container_mob.png';
import Container_tablet from '../../../assets/how-its-work/Container_tablet.png';
import Container_pc from '../../../assets/how-its-work/Container_pc.png';
import Search_mob from '../../../assets/how-its-work/Search_mob.png';
import Search_tablet from '../../../assets/how-its-work/Search_tablet.png';
import Search_pc from '../../../assets/how-its-work/Search_pc.png';
import { DiscoveryCard } from './DiscoveryCard';
import LinkGreen from '../../../icons/LinkGreen.svg';
import ChatPurple from '../../../icons/how-its-work/discovery-section/ChatPurple.svg';
import CalendarGreen from '../../../icons/how-its-work/discovery-section/CalendarGreen.svg';

const discoveryListCard = [
  {
    title: 'Discover Your Ultimate No-Code Platform',
    features: [
      {
        icon: LinkGreen,
        text: 'We provide excellent technical support to ensure that your applications work seamlessly with our components',
      },
      {
        icon: ChatPurple,
        text: 'Our team of experts is available to help you achieve your business goals and guide you through the development process.',
      },
      {
        icon: CalendarGreen,
        text: 'With Cosmith.io, you dont need any coding expertise to create amazing applications.',
      },
    ],
    images: {
      mobile: {
        src: Container_mob,
        width: 390,
      },
      tablet: {
        src: Container_tablet,
        width: 482,
      },
      pc: {
        src: Container_pc,
        width: 588,
      },
    },
    imageUrl: Container_pc,
    reversed: true,
  },
  {
    title: 'Unleashing Your Creativity with Cosmith.io',
    features: [
      {
        icon: LinkGreen,
        text: 'We provide excellent technical support to ensure that your applications work seamlessly with our components',
      },
      {
        icon: ChatPurple,
        text: 'Our team of experts is available to help you achieve your business goals and guide you through the development process.',
      },
      {
        icon: CalendarGreen,
        text: 'With Cosmith.io, you dont need any coding expertise to create amazing applications.',
      },
    ],
    images: {
      mobile: {
        src: Search_mob,
        width: 390,
      },
      tablet: {
        src: Search_tablet,
        width: 482,
      },
      pc: {
        src: Search_pc,
        width: 588,
      },
    },
    imageUrl: Search_pc,
    reversed: false,
  },
];

export const DiscoverySection = () => {
  return (
    <ul>
      {discoveryListCard.map(
        ({ images, imageUrl, title, reversed, features }) => (
          <li key={title}>
            <DiscoveryCard
              imageUrl={imageUrl}
              images={images}
              reversed={reversed}
              title={title}
              features={features}
            />
          </li>
        )
      )}
    </ul>
  );
};
