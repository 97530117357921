import { useState } from 'react';
import { SectionMainText } from '../generic/landing/SectionMainText';
import { Button } from '../generic';
import { ArrowRight } from '../../assets/how-its-work/ArrowRight';

export const Paragraphs = ({ paragraphs }: { paragraphs: string[] }) => {
  const [showAll, setShowAll] = useState(false);

  const filtredParagraphs = showAll ? paragraphs : paragraphs.slice(0, 2);

  return (
    <>
      <ul className="flex flex-col gap-3 mt-4 lg:mt-5">
        {filtredParagraphs.map((paragraph) => (
          <li key={paragraph}>
            <SectionMainText className="text-start">
              {paragraph}
            </SectionMainText>
          </li>
        ))}
      </ul>
      {paragraphs.length > 2 && !showAll && (
        <Button
          type="link"
          className="tracking-[-0.01rem] mt-2 lg:leading-[1.44] lg:tracking-[-0.014rem] lg:mt-[14px] group"
          onClick={() => setShowAll(true)}
        >
          Read more
          <ArrowRight className="fill-cosmith-primary-900/60 group-hover:fill-cosmith-primary-900" />
        </Button>
      )}
    </>
  );
};
