import { createBrowserRouter } from 'react-router-dom';
import { Routes } from './util/constants';

import { Cases } from './components/product/Cases';
import { Overview } from './components/product/Overview';
import { AuthLayout } from './layouts/AuthLayout';
import { AdminLayout } from './layouts/AdminLayout';
import { ProductCardLayout } from './layouts/ProductCardLayout';
import { MyComponentsPage } from './pages/MyComponentsPage';
import { ProductPricingPage } from './pages/ProductPricingPage';
import { PageNotFound } from './pages/PageNotFound';
import { CategoriesPage } from './pages/CategoriesPage';
import { BillingPage } from './pages/BillingPage';
import { ProductInstallationPage } from './pages/ProductInstallationPage';

import { MainPageLayout } from './layouts/MainPageLayout';

import { LoginPage } from './pages/auth/LoginPage';
import { LogoutPage } from './pages/auth/LogoutPage';
import { ResetPasswordPage } from './pages/auth/ResetPasswordPage';
import { ConfirmEmailPage } from './pages/auth/ConfirmEmailPage';
import { RegisterPage } from './pages/auth/RegisterPage';
import { WaitingEmailConfirmationPage } from './pages/auth/WaitingEmailConfirmationPage';
import { MainPage } from './pages/MainPage';
import { SolutionsPage } from './pages/SolutionsPage';
import { CategoryPage } from './pages/CategoryPage';
import { TalkToAnExpert } from './pages/TalkToAnExpert';
import { HowItsWorkPage } from './pages/HowItsWorkPage';
import { MyProductPricingPage } from './pages/MyProductPricingPage';
import { UserCaseArticle } from './pages/UserCaseArticle';
import { UserCase } from './pages/UserCase';
import { MyTalkToAnExpert } from './pages/MyTalkToAnExpert';

const router = createBrowserRouter([
  {
    element: <MainPageLayout />,
    path: '/',
    children: [
      { element: <MainPage />, index: true },
      {
        path: Routes.openCategoryProductPricing(),
        element: <ProductPricingPage />,
      },
      {
        path: Routes.openCategoryProduct(),
        element: <ProductCardLayout />,
        children: [
          {
            path: Routes.openCategoryProductOverview(),
            element: <Overview />,
          },
          {
            path: Routes.openCategoryProductInstallation(),
            element: <ProductInstallationPage />,
          },
          {
            path: Routes.openCategoryProductCases(),
            element: <Cases />,
          },
          {
            path: Routes.openCategoryProductVersions(),
            element: <div>versions</div>,
          },
        ],
      },
      {
        path: 'talk-to-expert',
        element: <TalkToAnExpert />,
      },
      {
        path: 'category',
        element: <CategoryPage />,
      },
      {
        path: 'solutions',
        element: <SolutionsPage />,
      },
      {
        path: 'user-case',
        element: <UserCase />,
      },
      {
        path: 'user-case/:id',
        element: <UserCaseArticle />,
      },
      {
        path: 'work',
        element: <HowItsWorkPage />,
      },
      {
        path: 'pricing',
        element: <div>Pricing</div>,
      },
      {
        path: 'help',
        element: <div>Help</div>,
      },
      {
        path: '*',
        element: <PageNotFound />,
      },
    ],
  },
  {
    element: <AdminLayout />,
    children: [
      {
        path: Routes.myComponents(),
        element: <MyComponentsPage />,
      },
      {
        path: Routes.myCategory(),
        element: <CategoriesPage />,
      },
      {
        path: Routes.myCategoryProductPricing(),
        element: <MyProductPricingPage />,
      },
      {
        path: Routes.myCategoryProduct(),
        element: <ProductCardLayout />,
        children: [
          {
            path: Routes.myCategoryProductOverview(),
            element: <Overview />,
          },
          {
            path: Routes.myCategoryProductInstallation(),
            element: <ProductInstallationPage />,
          },
          {
            path: Routes.myCategoryProductCases(),
            element: <Cases />,
          },
          {
            path: Routes.myCategoryProductVersions(),
            element: <div>versions</div>,
          },
        ],
      },
      {
        path: Routes.myTalkToAnExpert(),
        element: <MyTalkToAnExpert />,
      },
      {
        path: 'my/billing',
        element: <BillingPage />,
      },
      {
        path: Routes.mySettings(),
        element: <div>Setting</div>,
      },
      {
        path: 'my/help',
        element: <div>Help</div>,
      },
    ],
  },
  {
    element: <AuthLayout />,
    children: [
      {
        path: Routes.login(),
        element: <LoginPage />,
      },
      {
        path: Routes.register(),
        element: <RegisterPage />,
      },
      {
        path: Routes.resetPassword(),
        element: <ResetPasswordPage />,
      },
      {
        path: Routes.confirmEmail(),
        element: <ConfirmEmailPage />,
      },
      {
        path: Routes.waitingEmailConfirmation(),
        element: <WaitingEmailConfirmationPage />,
      },
      {
        path: '*',
        element: <PageNotFound />,
      },
    ],
  },
  {
    path: Routes.logout(),
    element: <LogoutPage />,
  },
]);

export default router;
