import { NavLink } from 'react-router-dom';
import { MainLogo } from '../../../../icons/Logo';
import { CopyrightNotice } from './CopyrightNotice';
import { FooterNavbar } from './FooterNavbar';
import FlagUa from '../../../../assets/icons/flagUA.svg';

export const Footer = () => (
  <div className="bg-cosmith-primary-900">
    <div className="max-w-[1920px] m-auto">
      <div className="px-4 lg:px-8 lg:bg-[url('/src/assets/main-page/Background.png')] bg-no-repeat bg-center xl:px-32">
        <div className="py-12 flex flex-col gap-8 lg:flex-row lg:justify-between lg:gap-10.5">
          <div className="flex flex-col gap-8 lg:gap-6 ">
            <NavLink to={'/'}>
              <MainLogo type="white" />
            </NavLink>
            <div className="text-white flex gap-1.5">Proudly made in 
            <img src={FlagUa} alt='flag of Ukraine' width={24} height={24} />
             Ukraine </div>
          </div>
          <FooterNavbar />
        </div>
      </div>
      <CopyrightNotice />
    </div>
  </div>
);
