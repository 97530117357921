import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import {
  productSearchState,
  productSortState,
} from '../../state';
import { useCollection } from 'react-firebase-hooks/firestore';
import { Query, collection } from 'firebase/firestore';
import { firestore } from '../../lib/firebase';
import { IFirebaseProduct } from '../../types';
import { Product } from '../../state/models';
import { DataOptions } from 'react-firebase-hooks/firestore/dist/firestore/types';

const query = collection(firestore, 'products') as Query<IFirebaseProduct>;

const options: DataOptions<IFirebaseProduct> = {
  snapshotListenOptions: { includeMetadataChanges: true },
};

type QueryOptions = {
  status: 'ready_to_install' | 'all';
};

export function useProductList (
  queryOptions: QueryOptions = { status: 'all' }
) {
  // const filter = useRecoilValue(productFilterState);
  const search = useRecoilValue(productSearchState);
  const sort = useRecoilValue(productSortState);

  const [loadedProducts, loading, error] = useCollection<IFirebaseProduct>(
    query,
    options
  );

  const allProducts = useMemo(() => {
    if (!loadedProducts) {
      return [];
    }

    return loadedProducts.docs
      .filter((p) => queryOptions.status === 'all' || p.data().status === queryOptions.status)
      .map((p) => new Product({ firebaseId: p.id, ...p.data() }));
  }, [loadedProducts]);

  const products = useMemo(() => {
    let result = [...allProducts];

    if (search) {
      const searchString = search.toLowerCase();

      result = result.filter((p) => p.searchString.search(searchString) > -1);
    }

    if (sort) {
      result.sort((p1, p2) => p1.price - p2.price);
    }

    return result;
  }, [allProducts, search, sort]);

  return {
    products,
    loading,
    error,
  };
}
