import { Children, ReactElement, cloneElement } from 'react';
import { FormItemVariant } from '../../../types';

interface Props {
  variant?: FormItemVariant;
  className?: string;
  children: ReactElement[];
}

export function FormControl (props: Props) {
  const { className = '', variant = 'normal' } = props;

  const renderChildren = () => {
    return Children.map(props.children, (child) => {
      return cloneElement(child, { variant });
    });
  };

  return (
    <div className={`cosmith-form-control w-full space-y-2 ${className}`}>
      {renderChildren()}
    </div>
  );
}
