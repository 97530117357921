import { SetterOrUpdater } from 'recoil';
import { Select, SelectOptions } from '../generic/Select';

interface Props {
  value: string;
  onChange: SetterOrUpdater<string>;
  className?: string;
}

export default function ProductSorter ({ value, onChange, className = '' }: Props): JSX.Element {

  const sortList: SelectOptions[] = [
    { id: '0', value: '',        label: 'Sort Apps By', placeholder: true },
    { id: '1', value: 'top-100', label: 'Top 100 Apps' },
    { id: '2', value: 'adalo',   label: 'Adalo' },
    { id: '3', value: 'beta',    label: 'Beta' },
  ];

  return (
    <Select
      className={className}
      label="Sort Apps By"
      value={value}
      options={sortList}
      onChange={onChange}
    />
  );
}
