import { AdaptiveImageCard } from '../../generic/AdaptiveImageCard';
import { ResponsivePicture } from '../../generic/ResponsivePicture';
import { BuyNowButton } from '../../generic/landing/BuyNowButton';
import { SectionTitle } from '../../generic/landing/SectionTitle';

interface IImages {
  mobile: {
    src: string;
    width: number;
  };
  tablet: {
    src: string;
    width: number;
  };
  pc: {
    src: string;
    width: number;
  };
}

interface IFeature {
  icon: string;
  text: string;
}

interface IDiscoveryProp {
  images: IImages;
  imageUrl: string;
  reversed: boolean;
  title: string;
  features: IFeature[];
}

export const DiscoveryCard = ({
  imageUrl,
  images,
  reversed,
  title,
  features,
}: IDiscoveryProp) => {
  return (
    <div className="relative">
      <div
        className={`pt-6 pb-6 lg:p-0 lg:${
          reversed ? 'mr-0' : 'mr-[-2rem]'
        } xl:mr-0`}
      >
        <AdaptiveImageCard
          reversed={reversed}
          secondChildren={
            <ResponsivePicture
              imageUrl={imageUrl}
              images={images}
              className={`reletive flex items-center 
                          after:content-[' '] after:h-[358px] after:w-[358px]
                          after:absolute after:bg-[url('/src/assets/BackgroundRight.svg')] after:bg-no-repeat after:bg-left
                          sm:after:h-[588px] sm:after:w-[588px]
                          lg:after:bg-[url('/src/assets/BackgroundLeft.svg')] lg:after:${
                            reversed ? 'left-0' : 'left-1/2'
                          } lg:after:bg-left lg:after:${
                            reversed ? 'ml-[-2rem]' : 'ml-0'
                          }
                          xl:after:ml-[-8rem]`}
            />
          }
        >
          <div>
            <SectionTitle title={title} />
            <ul className="flex flex-col gap-6 my-8 ">
              {features.map(({ icon, text }) => (
                <li
                  key={text}
                  className="flex gap-4 flex-col items-center lg:gap-6 lg:flex-row"
                >
                  <img src={icon} alt="icon" width={48} height={48} />
                  <p className="text-center leading-[1.5] tracking-[-0.01rem] opacity-60 lg:text-left">
                    {text}
                  </p>
                </li>
              ))}
            </ul>
            <div className="flex justify-center lg:block">
              <BuyNowButton />
            </div>
          </div>
        </AdaptiveImageCard>
      </div>
    </div>
  );
};
