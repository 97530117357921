import { useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { CardHeader } from '../components/product/CardHeader';
import { ProductNavigation } from '../components/product/ProductNavigation';
import { useCurrentProduct } from '../hooks';
import { Breadcrumbs } from '../components/layout';
import { Routes } from '../util/constants';
import { useIsUserAuth } from '../hooks/auth/useIsUserAuth';
import { Preloader } from '../components/generic';
import { EmptyDataResponse } from '../components/generic';

export const ProductCardLayout = () => {
  const { product, productName, loading } = useCurrentProduct();
  const isUserAuth = useIsUserAuth();

  const crumbs = useMemo(() => {
    return [
      {
        label: 'Category',
        to: isUserAuth ? Routes.myCategory() : Routes.category(),
      },
      { label: product ? product.title : '' },
    ];
  }, [product]);

  if (loading) {
    return <Preloader visible={loading} />;
  }
  if (!product) {
    return <EmptyDataResponse />;
  }

  return (
    <div className="flex flex-col h-full overflow-auto">
      <Breadcrumbs crumbs={crumbs} />

      <CardHeader />

      <div className="content flex flex-col my-8 pt-4 md:pt-0">
        <ProductNavigation productName={productName} />

        <div className="mt-6">{product && <Outlet />}</div>
      </div>
    </div>
  );
};
