export const RelatedCategories = ({
  relatedCategories,
}: {
  relatedCategories: string[];
}) => {
  return (
    <div className="lg:mb-6">
      <p className="font-semibold leading-6 tracking-[-0.16px] mb-4 lg:mb-[14px]">
        Related categories
      </p>
      <ul className="flex gap-4 flex-wrap">
        {relatedCategories.map((category) => (
          <li
            key={category}
            className="px-2 rounded-[20px] border border-solid border-cosmith-primary-900/20 bg-cosmith-primary-900 text-white text-sm tracking-[-0.14px] font-medium"
          >
            {category}
          </li>
        ))}
      </ul>
    </div>
  );
};
